import { useContext, useMemo } from 'react';
import { AppContext } from '../context';
import translations from '../translation/index';

function useUIText() {
  const { instanceInfo } = useContext(AppContext);
  const mainLanguage = instanceInfo.mainLanguage;

  const uiText = useMemo(() => {
    if (Object.keys(translations).includes(mainLanguage)) {
      return translations[mainLanguage];
    }
    return translations['English']
    
  }, [mainLanguage]);

  return uiText;
}

export default useUIText;