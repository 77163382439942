import React, { useRef, useEffect } from "react";

const AutoResizeTextarea = ({placeholder, color, value, onChange}) => {
	const textareaRef = useRef(null);

   useEffect(() => {
			const textarea = textareaRef.current;
			if (textarea) {
				// Ensure height is auto before measuring scrollHeight
				textarea.style.height = "auto";
				if (textarea.scrollHeight > 60) {
					const newHeight = Math.max(52, textarea.scrollHeight);
					textarea.style.height = `${newHeight}px`;
				} else {
					textarea.style.height = "52px";
				}
			}
		}, [value]);

	return (
		<textarea
			ref={textareaRef}
			type="text"
			id="problem"
			name="problem"
			rows={1}
			className="customer-textarea"
			style={{
				borderColor: color,
				maxHeight: "250px",
				minHeight: "52px",
				paddingBottom: "10px"
			}}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
		/>
	);
};

export default AutoResizeTextarea;
