import Switch from "./Switch";
import Logo from "../assets/logo-ver2.svg";
import { useLocation } from "react-router-dom";
import { useContext, useMemo } from "react";
import { AppContext } from "../context";

const Navbar = ({ logo, typeOfDemo, handleViewChange, onLogoClick }) => {
  const { instanceInfo } = useContext(AppContext);
	const loc = useLocation();
	const path = loc.pathname;

	const view = useMemo(() => {
		if (path === "/installer") return "Installer"
		if (path === "/customer") return "Customer"
		return instanceInfo.typeOfDemo
	}, [path, instanceInfo]);

	if (view === "") return null;
	return (
		<div className="navbar">
			<div className="navbar-logo">
					<img
						src={logo || Logo}
						alt="Logo"
						onClick={onLogoClick}
						style={{ cursor: "pointer"}}
					/>

				{typeOfDemo === "Both" ? (
					<Switch view={view} handleViewChange={handleViewChange} />
				) : null}
			</div>
		</div>
	);
};

export default Navbar;
