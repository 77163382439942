import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { handleSendResult, handleFeedbackSubmit } from "../zaps/zaps";
import { getBrightness, audioBlobToBase64 } from "../utils";
import StepsComponent from "./StepsComponent";
import NoResults from "./NoResults";
import ProgressBar from "./ProgressBar";
import VoiceAnimation from "./VoiceAnimation";
import Warning from "./Warning";
import AutoSelect from "./AutoSelect";
import AutoSelectDistributorsCustomer from "./AutoSelectDistributorsCustomers";
import DownloadIcon from "../assets/download";
import ArrowRightIcon from "../assets/arrowRight";
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import ThumbUpIcon from "../assets/thumbUp";
import ThumbDownIcon from "../assets/thumbDown";
import MicrophoneIcon from "../assets/mic";
import Alert from "./Alert";
import useUIText from "../hooks/translation";
import Faqs from "./Faqs";
import AutoResizeTextarea from "./TextArea";
import Switch from "./Switch";
import Logo from "../assets/logo-ver2.svg";
import { useLocation } from "react-router-dom";
import AutoSelectManufacturerAdvCustomer from "./AutoSelectManufacturerAdvCustomer";

const languageCodeMap = {
  English: "en-US",
  German: "de-DE",
  French: "fr-FR",
  Polish: "pl-PL",
  Spanish: "es-ES",
};

const FormContainer = ({
  variables,
  setFeedbackIsAdded,
  logo,
  typeOfDemo,
  handleViewChange,
}) => {
  const uiText = useUIText();
  const mainLanguage = variables.mainLanguage;

  const loc = useLocation();
  const path = loc.pathname;

  const view = useMemo(() => {
    if (path === "/installer") return "Installer";
    if (path === "/customer") return "Customer";
    return typeOfDemo;
  }, [path, typeOfDemo]);

  const environment = process.env.REACT_APP_ENV;

  let options = [
    { value: "English", label: "English" },
    { value: "Arabic", label: "Arabic" },
    { value: "Bulgarian", label: "Bulgarian" },
    { value: "Chinese", label: "Chinese" },
    { value: "Czech", label: "Czech" },
    { value: "Danish", label: "Danish" },
    { value: "Dutch", label: "Dutch" },
    { value: "Estonian", label: "Estonian" },
    { value: "Finnish", label: "Finnish" },
    { value: "French", label: "French" },
    { value: "German", label: "German" },
    { value: "Greek", label: "Greek" },
    { value: "Hungarian", label: "Hungarian" },
    { value: "Indonesian", label: "Indonesian" },
    { value: "Italian", label: "Italian" },
    { value: "Japanese", label: "Japanese" },
    { value: "Korean", label: "Korean" },
    { value: "Latvian", label: "Latvian" },
    { value: "Lithuanian", label: "Lithuanian" },
    { value: "Norwegian", label: "Norwegian" },
    { value: "Polish", label: "Polish" },
    { value: "Portuguese", label: "Portuguese" },
    { value: "portuguese-brazilian", label: "Portuguese (Brazilian)" },
    { value: "Romanian", label: "Romanian" },
    { value: "Russian", label: "Russian" },
    { value: "Slovak", label: "Slovak" },
    { value: "Slovenian", label: "Slovenian" },
    { value: "Spanish", label: "Spanish" },
    { value: "Swedish", label: "Swedish" },
    { value: "Turkish", label: "Turkish" },
    { value: "Ukrainian", label: "Ukrainian" },
  ];

  // Check if the default language is already in the options
  let defaultOptionIndex = options.findIndex(
    (option) => option.value === mainLanguage
  );

  if (defaultOptionIndex !== -1) {
    // If it is, modify its label to include '(default)'
    options[
      defaultOptionIndex
    ].label = `${options[defaultOptionIndex].label} (default)`;
    // Move the default option to the top of the array
    options.unshift(options.splice(defaultOptionIndex, 1)[0]);
  } else {
    // If it's not, add it to the options at the top
    options.unshift({
      value: mainLanguage,
      label: `${mainLanguage} (default)`,
    });
  }
  const [reset, setReset] = useState(false);
  const [userInput, setUserInput] = useState({
    installer_fallback: true,
    gpt: true,
    company: variables?.slug,
    device: "",
    problem: "",
    language: mainLanguage,
  });

  const [feedbackAdded, setFeedbackAdded] = useState(false);
  const [showThumbs, setShowThumbs] = useState(true);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileError, setFileError] = useState(null);
  const [error, setError] = useState(null);
  const [titleClicked, setTitleClicked] = useState(false);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState(uiText?.status?.processing);
  const [delay, setDelay] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showData, setShowData] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);

  const [showBackButton, setShowBackButton] = useState(false);

  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [transcription, setTranscription] = useState("");
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
  const [backBtnHovered, setBackBtnHovered] = useState(false);
  const [showFeedbackInput, setShowFeedbackInput] = useState(false);
  const [feedbackInput, setFeedbackInput] = useState("");
  const [exampleQuestions, setExampleQuestions] = useState([]);
  const [email, setEmail] = useState("");
  const [displayedFaq, setDisplayedFaq] = useState(null);

  const resetForm = () => {
    setShowData(false);
    setShowQuestion(false);
    setShowProgressBar(false);
    setError(null);
    setFileError(null);
    setData({});
    setFeedbackAdded(false);
    setShowFeedbackInput(false);
    setShowThumbs(true);
    setShowBackButton(false);
    setBackBtnHovered(false);
    setDisplayedFaq(null);
    setUserInput((cur) => ({
      ...cur,
      installer_fallback: true,
      gpt: true,
      company: variables?.slug,
      device: "",
      problem: "",
    }));
    setReset(true);
  };

  useEffect(() => {
    setUserInput((prevUserInput) => ({
      ...prevUserInput,
      language: mainLanguage,
    }));
  }, [mainLanguage]);

  useEffect(() => {
    if (userInput.device !== "" && variables.type === "Manufacturer") {
      setExampleQuestions(
        variables.devices.find(
          (device) => device.deviceName === userInput.device
        ).exampleQuestions
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInput.device, variables.devices]);

  const fetchData = async () => {
    const element = document.getElementById("navbar");
    if (element) window.scrollTo(0, element.offsetTop);
    setShowQuestion(true);
    setTimeout(() => {
      setLoading(true);
    }, 10);
    try {
      // const environment = process.env.REACT_APP_ENV;
      let url = null;
      if (environment === "staging") {
        url = variables.newAi
          ? "https://new-ai.service-assistant-staging.com/SMA_get_instructions"
          : "https://new-ai.service-assistant-staging.com/get_instructions";
      }
      if (environment === "production") {
        url = variables.newAi
          ? "https://new-ai.service-assistant.ai/SMA_get_instructions"
          : "https://new-ai.service-assistant.ai/get_instructions";
      }

      if (url) {
        const res = await axios.post(
          url,
          { ...userInput },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data) {
          setData(res.data);
          console.log(res.data);
          console.log(variables);
          // const element = document.getElementById("results");
          // if (element) window.scrollTo(0, element.offsetTop);
          setFeedbackAdded(false);
        } else {
          setData([]);
        }
      }
    } catch (err) {
      console.log(err.message); // Log only the error message
      setError(err.message); // Set only the error message
      if (environment === "production") {
        handleSendResult(false, data, userInput, variables.slug);
      }
      setShowProgressBar(false);
      setShowQuestion(false);
      setProgress(0);
      setStatus("");
    }
    setLoading(false);
    setTitleClicked(false);
    setFileError(null);
    setProgress(100); // Set progress to 100
    setStatus(uiText?.status?.complete); // Set status to "Complete"
    setTimeout(() => {
      setProgress(0);
      setStatus("");
      setShowProgressBar(false);
      setShowQuestion(false);
      setShowData(true);
    }, 300);
  };

  useEffect(() => {
    let interval = null;
    if (loading && !delay) {
      setShowProgressBar(true);
      if (progress === 0) {
        setStatus(uiText?.status?.processing);
      }
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          let newProgress = prevProgress + 1;
          if (newProgress > 100) {
            newProgress = 100; // Ensure progress does not exceed 100
          }
          if (
            newProgress === 5 ||
            newProgress === 25 ||
            newProgress === 50 ||
            newProgress === 75
          ) {
            setStatus(
              newProgress === 5
                ? uiText?.status?.processing
                : newProgress === 25 || newProgress === 50
                ? uiText?.status?.searching
                : uiText?.status?.generating
            );
            clearInterval(interval);
            setDelay(true);
            setTimeout(() => setDelay(false), 500); // Add a delay of half a second
          }
          return newProgress;
        });
      }, 200); // Adjust the time here to control the speed of the progress bar
    } else if (!loading && progress > 1) {
      setProgress(100);
      setStatus(uiText?.status?.complete);
      setTimeout(() => {
        setProgress(0);
        setStatus("");
        setShowProgressBar(false);
        setTimeout(() => setShowData(true), 300); // Delay showing data until after ProgressBar has disappeared
      }, 500);
    }
    return () => clearInterval(interval);
  }, [loading, delay, progress, uiText]);

  const openFile = async (fileName, page, range) => {
    setFileLoading(true);
    try {
      let url = null;
      if (process.env.REACT_APP_ENV === "production") {
        url = "https://new-ai.service-assistant.ai/file/download";
      } else {
        url = "https://new-ai.service-assistant-staging.com/file/download";
      }
      const response = await axios({
        method: "POST",
        url,
        data: {
          filename: `${fileName}`,
          company:
            variables.type === "Manufacturer"
              ? variables.slug
              : userInput.company,
        },
        responseType: "arraybuffer",
      });
      if (response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        let url = window.URL.createObjectURL(blob);

        // Convert page and range to an array if they are not already
        // and convert elements to string if they are numbers
        const pageArray = Array.isArray(page)
          ? page.map(String)
          : [String(page)];
        const rangeArray = Array.isArray(range)
          ? range.map(String)
          : [String(range)];

        // Check if the range array is not empty
        if (rangeArray.length > 0 && rangeArray[0]) {
          url += "#page=" + rangeArray[0];
        }
        // If the range array is empty, check if the page array is not empty
        else if (pageArray.length > 0 && pageArray[0]) {
          url += "#page=" + pageArray[0];
        }

        if (window.confirm(uiText?.alerts?.openInNewTab)) {
          window.open(url, "_blank");
        }
      }
    } catch (err) {
      setFileError(err);
      console.log(err);
    }
    setFileLoading(false);
  };

  const handleSubmit = (e) => {
    e && e.preventDefault();
    if (!userInput) return;
    setData({});
    fetchData();
    // setUserInput({ device: "", problem: "", language: "English" });
    setError(null);
    setFileError(null);
    setShowBackButton(false);
  };

  useEffect(() => {
    if (Object.keys(data).length > 0 && environment === "production") {
      handleSendResult(true, data, userInput, variables.slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]); // This effect runs whenever `data` changes

  useEffect(() => {
    if (feedbackAdded) {
      setFeedbackIsAdded(true);
      setTimeout(() => {
        setShowThumbs(false);
        setFeedbackIsAdded(false);
      }, 3500);
    } else {
      setShowThumbs(true);
      setFeedbackIsAdded(false);
    }
  }, [feedbackAdded, setFeedbackIsAdded]);

  useEffect(() => {
    if (titleClicked) {
      handleSubmit();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [titleClicked]);

  // Cleanup function to stop recording and release media resources
  useEffect(() => {
    return () => {
      if (mediaRecorder) {
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [mediaRecorder]);

  if (!process.env.REACT_APP_GOOGLE_API_KEY) {
    throw new Error("REACT_APP_GOOGLE_API_KEY not found in the environment");
  }

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const recorder = new MediaRecorder(stream);
      recorder.start();
      console.log("Recording started");
      setUserInput({
        ...userInput,
        problem: "",
      });

      // Event listener to handle data availability
      recorder.addEventListener("dataavailable", async (event) => {
        console.log("Data available event triggered");
        const audioBlob = event.data;

        const base64Audio = await audioBlobToBase64(audioBlob);
        //console.log('Base64 audio:', base64Audio);
        setIsSpinnerVisible(true);
        try {
          const startTime = performance.now();

          const response = await axios.post(
            `https://speech.googleapis.com/v1/speech:recognize?key=${apiKey}`,
            {
              config: {
                encoding: "WEBM_OPUS",
                sampleRateHertz: 48000,
                languageCode: languageCodeMap[userInput.language] || "en-US",
                enableAutomaticPunctuation: true,
                useEnhanced: true,
                model: "latest_long",
              },
              audio: {
                content: base64Audio,
              },
            }
          );

          const endTime = performance.now();
          const elapsedTime = endTime - startTime;

          //console.log('API response:', response);
          console.log("Time taken (ms):", elapsedTime);

          if (response.data.results && response.data.results.length > 0) {
            setIsSpinnerVisible(false);
            setTranscription(
              response.data.results[0].alternatives[0].transcript
            );
            setUserInput({
              ...userInput,
              problem: response.data.results[0].alternatives[0].transcript,
            });
          } else {
            console.log(
              "No transcription results in the API response:",
              response.data
            );
            setTranscription(uiText?.form?.noTranscription);
          }
          setIsSpinnerVisible(false);
        } catch (error) {
          console.error(
            "Error with Google Speech-to-Text API:",
            error.response.data
          );
        }
      });

      setRecording(true);
      setMediaRecorder(recorder);
    } catch (error) {
      console.error("Error getting user media:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      console.log("Recording stopped");
      setRecording(false);
      // Stop the MediaStream
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
    }
  };

  const hideForm =
    showProgressBar ||
    error ||
    fileError ||
    (showData && Object.keys(data).length > 0) ||
    loading ||
    showQuestion ||
    displayedFaq !== null;

  useEffect(() => {
    if (
      !loading &&
      ((showData && Object.keys(data).length > 0) || error || fileError)
    ) {
      setTimeout(() => {
        setShowBackButton(true);
      }, 1000);
    }
  }, [showData, data, error, fileError, loading]);

  // const handleCheckboxhange = (event) => {
  // 	setUserInput({
  // 		...userInput,
  // 		installer_fallback: event.target.checked,
  // 	});
  // };

  const handleFaqClick = (faq) => {
    setDisplayedFaq(faq);
    setData({});
    setShowBackButton(true);
  };
  return (
    <div
      className={
        variables.faq?.length > 0
          ? "customer-container customer-container--faq"
          : "customer-container"
      }
    >
      <div className="navbar">
        <div className="navbar-logo">
          <div className="nav-left">
            <img
              src={logo || Logo}
              alt="Logo"
              onClick={resetForm}
              style={{ cursor: "pointer" }}
            />
            {showBackButton && (
              <div className="nav-button">
                <div className="back-btn">
                  <button
                    onClick={() => {
                      setShowData(false);
                      setShowQuestion(false);
                      setShowProgressBar(false);
                      setError(null);
                      setFileError(null);
                      setData({});
                      setFeedbackAdded(false);
                      setShowFeedbackInput(false);
                      setShowThumbs(true);
                      setShowBackButton(false);
                      setBackBtnHovered(false);
                      setDisplayedFaq(null);
                    }}
                    onMouseEnter={() => setBackBtnHovered(true)}
                    onMouseLeave={() => setBackBtnHovered(false)}
                    style={{
                      borderColor: "transparent",
                      backgroundColor: "transparent",
                      color: backBtnHovered ? variables.mainColor : "#6982A8",
                    }}
                  >
                    <span>+ {uiText?.askNewQuestion}</span>
                  </button>
                </div>
              </div>
            )}
          </div>
          {typeOfDemo === "Both" ? (
            <Switch view={view} handleViewChange={handleViewChange} />
          ) : null}
        </div>
      </div>
      <div className="customer-wrapper">
        <div className="customer">
          <div className={fileLoading ? "modal visible" : "modal"}>
            <div className="modal-content">
              <span>{uiText?.form?.loadingFile}</span>
            </div>
          </div>
          <div
            className="form-page"
            style={{
              justifyContent:
                variables.faq?.length > 0 ? "space-between" : "center",
            }}
          >
            {!hideForm && (
              <div className={`avatar-container ${hideForm ? "hide" : ""}`}>
                {/* <div className="avatar-logo">
									</div> */}
                <div className="avatar-text">
                  {error ||
                  showProgressBar ||
                  (showData && Object.keys(data).length > 0) ||
                  displayedFaq !== null ? null : (
                    <div className="avatar-info">{uiText.form.greetings}</div>
                  )}
                </div>
              </div>
            )}
            {variables.faq?.length > 0 && !hideForm && (
              <Faqs
                faqs={variables.faq}
                borderColor={variables.mainColor}
                displayedFaq={displayedFaq}
                handleFaqClick={handleFaqClick}
              />
            )}
            <div className={`form-container ${hideForm ? "hide" : ""}`}>
              <form
                onSubmit={handleSubmit}
                className={`form ${hideForm ? "hide" : ""}`}
                style={{
                  height: variables.faq?.length > 0 ? "100%" : "inherit",
                }}
              >
                <div
                  className="form-group"
                  style={{
                    marginTop: variables.faq?.length > 0 ? "0px" : "0",
                    justifyContent: "flex-end",
                    flexGrow: variables.faq?.length > 0 ? 1 : 0,
                    height: variables.faq?.length > 0 ? "100%" : "auto",
                  }}
                >
                  {variables.type === "Manufacturer" ? (
                    <div className="manufacturer-selection">
                      {" "}
                      {/* <label htmlFor="device">{`${uiText?.form?.device} *`}</label> */}
                      <div className="selection-title">
                        {!userInput.device
                          ? uiText.form.selectDevice
                          : uiText.form.yourDevice}
                      </div>
                      <div className="selection-container">
                        <div className="customer-selection">
                          <AutoSelect
                            items={variables.devices}
                            setSelectedDevice={(device) =>
                              setUserInput({ ...userInput, device })
                            }
                            device={userInput.device}
                            menuPlacement={"top"}
                          />
                        </div>
                      </div>
                    </div>
                  ) : variables.type === "Distributor" ? (
                    <AutoSelectDistributorsCustomer
                      items={variables.deviceTypes}
                      userInput={userInput}
                      setUserInput={setUserInput}
                      setExampleQuestions={setExampleQuestions}
                      reset={reset}
                      setReset={setReset}
                    />
                  ) : (
                    <AutoSelectManufacturerAdvCustomer
                      items={variables.categoriesContainer}
                      userInput={userInput}
                      setUserInput={setUserInput}
                      setExampleQuestions={setExampleQuestions}
                      reset={reset}
                      setReset={setReset}
                    />
                  )}
                  {/* <label htmlFor="email">Email</label>
							<input
								type="text"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								placeholder={uiText?.form?.emailPlaceholder}
								className="email card"
							/> */}
                  {/* <label htmlFor="problem">{uiText?.form?.question} *</label> */}
                  <div className="input-container flex">
                    <AutoResizeTextarea
                      // rows={8}
                      placeholder={
                        recording || isSpinnerVisible
                          ? ""
                          : transcription === uiText?.form?.noTranscript
                          ? uiText?.form?.noTranscriptMessage
                          : uiText?.form?.questionPlaceholder
                      }
                      value={userInput.problem || ""}
                      onChange={(e) =>
                        setUserInput({ ...userInput, problem: e.target.value })
                      }
                      color={variables.mainColor}
                    />
                    {/* <div className="microphone-background"> */}

                    {!recording && (
                      <div className="right-side">
                        <div
                          className={
                            isSpinnerVisible
                              ? "microphone disabled"
                              : "microphone"
                          }
                          onClick={isSpinnerVisible ? null : startRecording}
                          style={{ backgroundColor: "white" }}
                        >
                          <MicrophoneIcon color="#B3CADD" />
                        </div>
                        <button
                          type="submit"
                          className={
                            userInput.device === "" ||
                            userInput.problem === "" ||
                            !variables?.aiTrained
                              ? "submit-btn disabled"
                              : "submit-btn"
                          }
                          disabled={
                            userInput.device === "" ||
                            userInput.problem === "" ||
                            !variables?.aiTrained
                          }
                          style={{
                            backgroundColor: variables.mainColor,
                            color:
                              getBrightness(variables.mainColor) > 155
                                ? "black"
                                : "white",
                            border: `1px solid ${variables.mainColor}`,
                            width: "32px",
                            height: "32px",
                            padding: "8px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ArrowRightIcon
                            color={
                              getBrightness(variables.mainColor) > 155
                                ? "#000000"
                                : "#ffffff"
                            }
                          />
                        </button>
                      </div>
                    )}

                    {/* </div> */}
                  </div>
                  {isSpinnerVisible && (
                    <div
                      style={{
                        position: "relative",
                        marginInline: "auto",
                        top: "16px",
                      }}
                    >
                      <div className="spnr"></div>
                    </div>
                  )}
                  {recording && (
                    <div style={{ position: "relative", marginInline: "auto" }}>
                      <VoiceAnimation />
                      <div className="stop-recording__container">
                        <div
                          className="stop-recording__background"
                          style={{ backgroundColor: variables.mainColor }}
                        ></div>
                        <div
                          className="stop-recording"
                          onClick={stopRecording}
                          style={{ backgroundColor: variables.mainColor }}
                        >
                          <span
                            style={{
                              borderColor:
                                getBrightness(variables.mainColor) > 155
                                  ? "#000000"
                                  : "#ffffff",
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <button
								type="submit"
								className={
									userInput.device === "" ||
									userInput.problem === "" ||
									!variables?.aiTrained
										? "submit-btn disabled"
										: "submit-btn"
								}
								disabled={
									userInput.device === "" ||
									userInput.problem === "" ||
									!variables?.aiTrained
								}
								style={{
									backgroundColor: variables.mainColor,
									color:
										getBrightness(variables.mainColor) > 155
											? "black"
											: "white",
									border: `1px solid ${variables.mainColor}`,
								}}
							>
								{uiText?.form?.submit}
							</button> */}
                  {/* <label className="options-checkbox mobile">
								Protection System
								<input
									name="origin"
									type="checkbox"
									value={userInput.installer_fallback}
									checked={userInput.installer_fallback}
									onChange={handleCheckboxhange}
								/>
								<span className="checkmark"></span>
							</label> */}
                </div>
                {!hideForm && (
                  <div>
                    {exampleQuestions.length && !variables.faq?.length ? (
                      <>
                        {/* <label htmlFor="problem" style={{ marginTop: "20px", textAlign: 'center' }}>
												{uiText?.form?.exampleQuestions}:
											</label> */}
                        {/* <div
												className="example-questions first"
												style={{ marginBottom: "8px" }}
											>
												<div
													key={0}
													className="example-question"
													onClick={() =>
														isSpinnerVisible || recording || loading
															? null
															: setUserInput({
																	...userInput,
																	problem: exampleQuestions[0],
																})
													}
													style={{
														borderColor: variables.mainColor,
														cursor:
															isSpinnerVisible || recording
																? "not-allowed"
																: "pointer",
													}}
												>
													{exampleQuestions[0]}
												</div>
											</div> */}
                        <div className="example-questions">
                          {exampleQuestions.map((question, index) => (
                            <div
                              key={index + 1}
                              className="example-question"
                              onClick={() =>
                                isSpinnerVisible || recording || loading
                                  ? null
                                  : setUserInput({
                                      ...userInput,
                                      problem: question,
                                    })
                              }
                              style={{
                                borderColor: variables.mainColor,
                                cursor:
                                  isSpinnerVisible || recording
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                            >
                              {question}
                            </div>
                          ))}
                        </div>
                      </>
                    ) : null}
                  </div>
                )}
              </form>
            </div>
            <div
              className={
                showProgressBar ||
                error ||
                fileError ||
                (showData && Object.keys(data).length > 0) ||
                displayedFaq !== null
                  ? "feedback-container show"
                  : "feedback-container"
              }
              id="results"
            >
              {/* <div className="ai-avatar results">
						<ResultsIcon
							circleColor={variables.mainColor}
							color={
								getBrightness(variables.mainColor) > 155 ? "black" : "white"
							}
						/>
						<span>Results</span>
						<label className="options-checkbox desktop">
							Protection System
							<input
								name="origin"
								type="checkbox"
								value={userInput.installer_fallback}
								checked={userInput.installer_fallback}
								onChange={handleCheckboxhange}
							/>
							<span className="checkmark"></span>
						</label>
					</div> */}
              <div
                className={
                  !Object.keys(data).length > 0 ||
                  showProgressBar ||
                  displayedFaq !== null
                    ? "feedback card"
                    : "feedback card hide"
                }
                // style={{
                // 	padding:
                // 		!Object.keys(data).length > 0 || showProgressBar
                // 			? "1.5rem"
                // 			: "0",
                // }}
              >
                {variables.faq?.length > 0 && displayedFaq !== null && (
                  <Faqs
                    faqs={variables.faq}
                    borderColor={variables.mainColor}
                    displayedFaq={displayedFaq}
                    handleFaqClick={handleFaqClick}
                  />
                )}
                {error && <NoResults txt={variables.customFallbackMessage} />}
                {fileError && (
                  <Alert
                    message={uiText?.alerts?.fileOpenError}
                    duration={5000} // Duration in milliseconds
                    close={() => setFileError(null)}
                  />
                )}
                {showProgressBar && (
                  <div className="progress-desktop">
                    <ProgressBar
                      progress={progress}
                      status={status}
                      color={variables.mainColor}
                    />
                  </div>
                )}
                {showData && Object.keys(data).length > 0 ? (
                  <>
                    {data?.exact_file
                      ? (() => {
                          return (
                            <div className="feedback-top">
                              <div className="user-question">
                                {userInput.problem}
                              </div>
                              {/* <div
														className="feedback-top__question"
														style={{ flex: "1" }}
													>
														<div className="feedback-top__icon">
															<InfoIcon />
														</div>
														<div className="feedback-top__text">
															Is this the correct device? If not, select the
															correct device from related devices list.
														</div>
													</div> */}
                            </div>
                          );
                        })()
                      : null}

                    <div className="feedback-instructions">
                      {data?.model_response && !data?.empty_answer ? (
                        <>
                          <div className="feedback-documents__col">
                            {typeof data.warning === "string" &&
                            data.warning.length > 0 ? (
                              <Warning warningText={data.warning} />
                            ) : null}
                            <div
                              className="feedback-header"
                              style={{ marginTop: "1rem" }}
                            >
                              <span>{uiText?.result?.instructions}:</span>
                            </div>
                            <div className="feedback-instruction">
                              <StepsComponent
                                inputText={data.model_response}
                                language={userInput.language}
                              />
                            </div>
                          </div>
                        </>
                      ) : data?.model_response && data.empty_answer ? (
                        <NoResults txt={variables.customFallbackMessage} />
                      ) : null}
                      <div
                        className={
                          data?.exact_file
                            ? "feedback-documents"
                            : "feedback-documents none"
                        }
                        style={{ padding: "0px" }}
                      >
                        {data?.exact_file && !data.empty_answer
                          ? (() => {
                              let fileName;
                              if (Array.isArray(data.exact_file)) {
                                const fileObj = data.exact_file[0]; // Get the first object from the array
                                fileName = Object.keys(fileObj)[0]; // Get the key of the object which is the file name
                              } else {
                                fileName = data.exact_file;
                              }

                              // Convert page to an array if it's not already
                              const pageArray = Array.isArray(data.page_number)
                                ? data.page_number
                                : [data.page_number];
                              const pageText = pageArray.join(", ");
                              const isMultiplePages = pageArray.length > 1;

                              return (
                                <div
                                  className="feedback-documents__col"
                                  style={{ padding: "1rem 0 0" }}
                                >
                                  <div className="feedback-file customer__feedback-file">
                                    <div
                                      className="file-title"
                                      onClick={() =>
                                        openFile(
                                          fileName,
                                          data.page_number || [],
                                          data.page_range || []
                                        )
                                      }
                                      style={{ color: variables.mainColor }}
                                    >
                                      <DownloadIcon
                                        color={variables.mainColor}
                                        style={{ marginRight: "6px" }}
                                      />
                                      <div>
                                        <div>
                                          <span
                                            style={{
                                              color: variables.mainColor,
                                            }}
                                          >
                                            {uiText?.result?.findMoreInfo}{" "}
                                          </span>
                                          {pageText?.length ? (
                                            <span
                                              style={{
                                                color: variables.mainColor,
                                              }}
                                            >
                                              {isMultiplePages
                                                ? uiText?.result?.pages
                                                : uiText?.result?.page}{" "}
                                              {pageText}
                                            </span>
                                          ) : null}
                                        </div>
                                        <div className="file-title__small-text">
                                          {uiText?.result?.downloadFile}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })()
                          : null}
                      </div>
                    </div>
                    {Object.keys(data).length > 0 &&
                      data?.exact_file === null && (
                        <NoResults txt={variables.customFallbackMessage} />
                      )}
                  </>
                ) : null}
              </div>
              <div className="faq-mobile">
                {variables.faq?.length > 0 && displayedFaq !== null && (
                  <Faqs
                    faqs={variables.faq}
                    borderColor={variables.mainColor}
                    displayedFaq={displayedFaq}
                    handleFaqClick={handleFaqClick}
                  />
                )}
              </div>
            </div>
            {showProgressBar && (
              <div className="progress-mobile">
                <ProgressBar
                  progress={progress}
                  status={status}
                  color={variables.mainColor}
                />
              </div>
            )}
          </div>
          <div className="error-mobile">
            {error && <NoResults txt={variables.customFallbackMessage} />}
            {fileError && (
              <Alert
                message={uiText?.alerts?.fileOpenError}
                duration={5000} // Duration in milliseconds
                close={() => setFileError(null)}
              />
            )}
          </div>
          {/* {showBackButton && (
						<div className="back-btn">
							<button
								onClick={() => {
									setShowData(false);
									setShowQuestion(false);
									setShowProgressBar(false);
									setError(null);
									setFileError(null);
									setData({});
									setFeedbackAdded(false);
									setShowFeedbackInput(false);
									setShowThumbs(true);
									setShowBackButton(false);
									setBackBtnHovered(false);
									setDisplayedFaq(null);
								}}
								onMouseEnter={() => setBackBtnHovered(true)}
								onMouseLeave={() => setBackBtnHovered(false)}
								style={{
									borderColor: backBtnHovered
										? variables.mainColor
										: "transparent",
									backgroundColor: "transparent",
									color: backBtnHovered ? "#6982A8" : variables.mainColor,
								}}
							>
								<span>+ {uiText?.askNewQuestion}</span>
								<ArrowRightIcon
									color={
										getBrightness(variables.mainColor) > 155
											? "#000000"
											: "#ffffff"
									}
								/>
							</button>
						</div>
					)} */}
          {(showBackButton && showThumbs && data?.exact_file) ||
          displayedFaq !== null ? (
            <div
              className={
                showFeedbackInput ? "user-feedback show-input" : "user-feedback"
              }
            >
              {feedbackAdded ? (
                <div className="user-feedback__text">
                  {uiText?.result?.feedback.thankYou}
                </div>
              ) : showFeedbackInput ? (
                <div className="user-feedback__input">
                  <div
                    className="close-button__feedback "
                    onClick={() => setShowFeedbackInput(false)}
                  >
                    <CloseIcon />
                  </div>
                  <div className="feedback-text">
                    {uiText?.result?.feedback.description}
                  </div>
                  <textarea
                    value={feedbackInput}
                    onChange={(e) => setFeedbackInput(e.target.value)}
                    placeholder={uiText?.result?.feedback.inputPlaceholder}
                    type="text"
                    id="feedback-input"
                    name="feedback-input"
                    className="card"
                  />
                  <button
                    type="submit"
                    className="submit-btn"
                    style={{
                      backgroundColor: variables.mainColor,
                      color:
                        getBrightness(variables.mainColor) > 155
                          ? "black"
                          : "white",
                      border: `1px solid ${variables.mainColor}`,
                      marginTop: "12px",
                    }}
                    onClick={() => {
                      if (environment === "production") {
                        handleFeedbackSubmit(
                          "Thumb Down",
                          data,
                          userInput,
                          setFeedbackAdded,
                          variables.slug,
                          feedbackInput,
                          setShowFeedbackInput
                        );
                      } else {
                        setFeedbackAdded(true);
                        setShowFeedbackInput(false);
                      }
                    }}
                  >
                    <div className="thumb-button">
                      <ThumbDownIcon />
                      {uiText?.result?.feedback.submit}
                    </div>
                  </button>
                </div>
              ) : (
                <>
                  <div className="user-feedback__text">
                    {uiText?.result?.feedback.title}
                  </div>
                  <div className="user-feedback__buttons">
                    <div
                      className="user-feedback__button"
                      onClick={() => setShowFeedbackInput(true)}
                    >
                      <ThumbDownIcon />
                    </div>
                    <div
                      className="user-feedback__button"
                      onClick={() => {
                        if (environment === "production") {
                          handleFeedbackSubmit(
                            "Thumb Up",
                            data,
                            userInput,
                            setFeedbackAdded,
                            variables.slug
                          );
                        } else {
                          setFeedbackAdded(true);
                        }
                      }}
                    >
                      <ThumbUpIcon />
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default FormContainer;
