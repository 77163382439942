import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import "./App.css";
import axios from "axios";
import Installer from "./components/Installer";
import Customer from "./components/Customer";
import Loading from "./components/Loading";
import { defaultInstance } from "./utils/constants";
import { AppContext } from "./context";
import NotFound from "./components/NotFound";
import ErrorPage from "./components/ErrorPage";

function App() {
  const [instanceInfo, setInstanceInfo] = useState(defaultInstance);
  const [isLoading, setIsLoading] = useState(true);
  const [feedbackIsAdded, setFeedbackIsAdded] = useState(false);
  const [typeOfDemo, setTypeOfDemo] = useState("");
  const [error, setError] = useState(null);
  const [view, setView] = useState("Installer");

  const location = useLocation();

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const changeInstanceLanguage = (language) => {
    setInstanceInfo({
      ...instanceInfo,
      mainLanguage: language,
    });
  };

  const handleViewChange = (e) => {
    const newView = e.target.checked ? "Customer" : "Installer";
    setView(newView);
    handleNavigation(newView === "Customer" ? "/customer" : "/installer");
  };

  useEffect(() => {
    setIsLoading(true);
    const instanceId = process.env.REACT_APP_INSTANCE_ID;
    const environment = process.env.REACT_APP_ENV;

    // Check if instanceId is not available
    if (!instanceId) {
      setInstanceInfo(defaultInstance);
      setIsLoading(false);
      return;
    }

    let api;
    switch (environment) {
      case "development":
        api = "http://localhost:9000/instance";
        break;
      case "staging":
        api = "https://new-admin-api.service-assistant-staging.com/instance";
        break;
      case "production":
        api = "https://new-admin-api.service-assistant.ai/instance";
        break;
      default:
        console.warn(`Unsupported environment: ${environment}`);
        setIsLoading(false); // Set loading state to false if environment is unsupported
        return; // Exit useEffect early
    }

    // Ensure api is defined before proceeding
    if (!api) {
      console.error("API URL could not be determined.");
      setIsLoading(false);
      return;
    }

    const sendData = {
      instanceId: instanceId,
    };

    axios
      .get(api, {
        params: sendData,
      })
      .then((response) => {
        setInstanceInfo(response.data);
        console.log(response.data);
        setTypeOfDemo(response.data.typeOfDemo || "Both");
        setView(
          response.data.typeOfDemo === "Both"
            ? "Installer"
            : response.data.typeOfDemo
        );
        const pageLinks = document.getElementsByTagName("link");
        const pageLink = Array.from(pageLinks).find((link) => {
          return link.rel === "icon";
        });
        if (pageLink && response?.data?.favIcon) {
          pageLink.href = response.data.favIcon;
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath === "/customer") {
      setView("Customer");
    } else if (currentPath === "/installer") {
      setView("Installer");
    }
  }, [location.pathname]);

  useEffect(() => {
    // Redirect to a valid route based on typeOfDemo
    if (typeOfDemo === "Installer" && location.pathname !== "/") {
      window.history.replaceState(null, "", "/");
    } else if (typeOfDemo === "Customer" && location.pathname !== "/") {
      window.history.replaceState(null, "", "/");
    } else if (typeOfDemo === "Both" && location.pathname === "/") {
      window.history.replaceState(null, "", "/installer");
    }
  }, [typeOfDemo, location.pathname]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorPage statusCode={error.response?.status} message={error.message} />
    );
  }
  return (
    <AppContext.Provider value={{ instanceInfo, changeInstanceLanguage }}>
      <Routes>
        {typeOfDemo === "Installer" && (
          <Route
            path="/"
            element={
              <Installer
                logo={instanceInfo.logo}
                typeOfDemo={typeOfDemo}
                view={view}
                handleViewChange={handleViewChange}
                feedbackIsAdded={feedbackIsAdded}
                variables={instanceInfo}
                setFeedbackIsAdded={setFeedbackIsAdded}
              />
            }
          />
        )}
        {typeOfDemo === "Customer" && (
          <Route
            path="/"
            element={
              <Customer
                logo={instanceInfo.logo}
                typeOfDemo={typeOfDemo}
                handleViewChange={handleViewChange}
                feedbackIsAdded={feedbackIsAdded}
                variables={instanceInfo}
                setFeedbackIsAdded={setFeedbackIsAdded}
              />
            }
          />
        )}
        {typeOfDemo === "Both" && (
          <>
            <Route path="/" element={<Navigate to="/installer" />} />
            <Route
              path="/installer"
              element={
                <Installer
                  logo={instanceInfo.logo}
                  typeOfDemo={typeOfDemo}
                  handleViewChange={handleViewChange}
                  feedbackIsAdded={feedbackIsAdded}
                  variables={instanceInfo}
                  setFeedbackIsAdded={setFeedbackIsAdded}
                />
              }
            />
            <Route
              path="/customer"
              element={
                <Customer
                  logo={instanceInfo.logo}
                  typeOfDemo={typeOfDemo}
                  handleViewChange={handleViewChange}
                  feedbackIsAdded={feedbackIsAdded}
                  variables={instanceInfo}
                  setFeedbackIsAdded={setFeedbackIsAdded}
                />
              }
            />
          </>
        )}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AppContext.Provider>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
