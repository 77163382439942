import React from "react";
// import FaqsAccordion from "./FaqsAccordion";
import PencilIcon from "../assets/pencil.svg";
import StarIcon from "../assets/star.svg";
import LightbulbIcon from "../assets/lightbulb.svg";
import CompassIcon from "../assets/compass.svg";
import useUIText from "../hooks/translation";

function renderIcon(iconName) {
	switch (iconName) {
		case "PENCIL":
			return <img src={PencilIcon} alt="pencil"/>;
		case "STAR":
			return <img src={StarIcon} alt="star"/>;
		case "LIGHTBULB":
			return <img src={LightbulbIcon} alt="lightbulb" />;
		case "COMPASS":
			return <img src={CompassIcon} alt="compass" />;
		default:
			return null; // Or return a default icon or nothing
	}
}

const Faqs = ({
	faqs,
	borderColor,
	displayedFaq,
	handleFaqClick,
	tabVersion = false,
}) => {
	const uiText = useUIText();
	return (
		<div className="faq-container">
			{displayedFaq === null ? (
				<>
					<div
						className="faq-main__title"
						style={{ textAlign: tabVersion ? "left" : "center" }}
					>
						{uiText.faq.title}
					</div>
					<div className="faq-grid">
						{faqs.map((faq, index) => (
							<div
								className="faq-card"
								style={{ borderColor: borderColor }}
								key={index}
								onClick={() => handleFaqClick(faq)}
							>
								<div className="faq-card__icon">{renderIcon(faq.icon)}</div>
								<div className="faq-card__question">{faq.question}</div>
								<div
									className="faq-card__answer"
									dangerouslySetInnerHTML={{ __html: faq.answer }}
								/>
							</div>
						))}
					</div>
				</>
			) : (
				<>
					<div className="faq-question">{displayedFaq.question}</div>
					<div
						className="feedback-instructions faq-answer"
						dangerouslySetInnerHTML={{ __html: displayedFaq.answer }}
					/>
					{/* <FaqsAccordion
						faqs={faqs}
						borderColor={borderColor}
						handleFaqClick={handleFaqClick}
					/> */}
				</>
			)}
		</div>
	);
};

export default Faqs;
