import CustomerFormContainer from "./CustomerFormContainer";

const Customer = ({
	logo,
	typeOfDemo,
	handleViewChange,
	variables,
	setFeedbackIsAdded,
}) => {
	return (
		<CustomerFormContainer
			variables={variables}
			setFeedbackIsAdded={setFeedbackIsAdded}
			logo={logo}
			typeOfDemo={typeOfDemo}
			handleViewChange={handleViewChange}
		/>

	);
};

export default Customer;